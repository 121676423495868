'use client'

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react'
import { useRouter, useSearchParams } from 'next/navigation'
import Cookies from 'js-cookie'

import {
  KEY_CACHE_PROFILE_USER,
  KEY_STORAGE_FIRST_NAME,
  profilesServiceRoutes,
  KEY_STORAGE_COMPANY,
  TypeRolesPermisson,
  profilesPermisson,
  KEY_STORAGE_USER,
  KEY_CACHE_TOKEN
} from '../../constants/user-profiles-enums'

import { isEmptyObject } from '@/utils/object'

import { addToStorage } from '@/services/StorageService'
import { apiMoradia } from '@/services/api'

import { TypeApiUserAcesso } from '@/@types/autenticacao'
import { isCadastroAtivo } from '@/services/BeneficiaryServices'

interface AuthContextProps {
  handleLogin: () => Promise<void>
  handleLogout: () => void
}

interface AuthContextProviderProps {
  children: React.ReactNode
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps)

function AuthContextProvider({ children }: AuthContextProviderProps) {
  const { push } = useRouter()

  const handleLogin = useCallback(async () => {
    push(
      `${process.env.NEXT_PUBLIC_LOGIN_WEB_APP}?app-key=${process.env.NEXT_PUBLIC_APP_KEY}`
    )
  }, [push])

  const handleLogout = useCallback(() => {
    Cookies.remove(KEY_CACHE_TOKEN)
    Cookies.remove(KEY_CACHE_PROFILE_USER)

    localStorage.removeItem(KEY_STORAGE_USER)
    localStorage.removeItem(KEY_STORAGE_COMPANY)
    localStorage.removeItem(KEY_STORAGE_FIRST_NAME)
  }, [])

  const valuesProvider = useMemo(() => {
    return {
      handleLogin,
      handleLogout
    }
  }, [handleLogin, handleLogout])

  return (
    <AuthContext.Provider value={valuesProvider}>
      {children}
    </AuthContext.Provider>
  )
}

function useAuthentication() {
  const context = useContext(AuthContext)

  if (isEmptyObject(context)) {
    throw new Error(
      'useAuthentication must be used within a AuthContextProvider'
    )
  }

  return context
}

function useFetchAndVerifyAcessoCidadao() {
  const searchParams = useSearchParams()
  const { replace } = useRouter()
  const { handleLogout } = useAuthentication()

  useEffect(() => {
    const fetchAndVerifyApiAcessoCidadao = async () => {
      const tokenUrl = searchParams.get('token')
      const tokenCache = Cookies.get(KEY_CACHE_TOKEN)

      if (tokenCache) {
        const idProfile = Cookies.get(KEY_CACHE_PROFILE_USER)

        if (idProfile !== undefined)
          return replace(`/${profilesServiceRoutes[JSON.parse(idProfile)]}`)
      }

      if (tokenUrl) {
        try {
          const response = await apiMoradia.post<TypeApiUserAcesso>(
            `acesso?token=${tokenUrl}`
          )

          const cadastroAtivo = await isCadastroAtivo()

          const user = response.data
          Cookies.set(KEY_CACHE_TOKEN, JSON.stringify(tokenUrl))

          addToStorage(KEY_STORAGE_USER, JSON.stringify(user))

          if (user.perfils.length == 0) {
            if (user.cpfCnpj.length <= 11) {
              Cookies.set(
                KEY_CACHE_PROFILE_USER,
                JSON.stringify(profilesPermisson.ROLE_CIDADAO)
              )
              if (user.isCadastroCompleto) {
                return replace('/cidadao/perfil')
              } else {
                if (cadastroAtivo) {
                  return replace('/cidadao/termos-de-uso')
                } else {
                  handleLogout()
                  return replace('/error')
                }
              }
            } else {
              Cookies.set(
                KEY_CACHE_PROFILE_USER,
                JSON.stringify(profilesPermisson.ROLE_EMPRESA)
              )
              if (user.isCadastroCompleto) {
                return replace('/empresa')
              } else {
                return replace('/empresa/cadastrar-empresa')
              }
            }
          } else {
            const numberRole =
              profilesPermisson[user.perfils[0] as TypeRolesPermisson]
            Cookies.set(KEY_CACHE_PROFILE_USER, JSON.stringify(numberRole))

            if (numberRole === 2 && !user.isCadastroCompleto) {
              return replace('/caixa/cadastrar-agente-caixa')
            }

            return replace(`/${profilesServiceRoutes[numberRole]}`)
          }
        } catch (error) {
          console.error('Erro ao processar solicitação à API:', error)

          Cookies.remove(KEY_CACHE_TOKEN)
          Cookies.remove(KEY_CACHE_PROFILE_USER)

          localStorage.removeItem(KEY_STORAGE_USER)
          localStorage.removeItem(KEY_STORAGE_COMPANY)
          localStorage.removeItem(KEY_STORAGE_FIRST_NAME)
        }
      }

      return replace('/')
    }

    fetchAndVerifyApiAcessoCidadao()
  }, [handleLogout, replace, searchParams])
}

export {
  AuthContextProvider,
  useAuthentication,
  useFetchAndVerifyAcessoCidadao
}

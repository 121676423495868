import { getInfoUser } from '../UserService'
import { apiMoradia } from '../api'

export type TypeFileBeneficiary = 'PDF' | 'IMAGEM'

export type TypeFileBeneficiaryTypes =
  | 'COMPROVANTE_MAE_SOLO_ATIPICA'
  | 'COMPROVANTE_MULHER_VITIMA_VIOLENCIA_DOMESTICA'
  | 'COMPROVANTE_FAMILIA_SITUACAO_VULNERABILIDADE_CHEFIADA_MULHER'
  | 'IDENTIDADE_BENEFICIARIO'
  | 'IDENTIDADE_CONJUGE'
  | 'TERMO_ADESAO_BENEFICIARIO'
  | 'COMPROVANTE_RESIDENCIA_ATUAL'
  | 'DECLARACAO_RESIDENCIA_12_MESES'
  | 'COMPROVANTE_CADASTRO_INTERESSE'
  | 'CERTIFICADO_APROVACAO_SUBSIDIO'
  | 'DOCUMENTO_ABERTURA_EMPENHO'
  | 'DECLARACAO_RESIDENCIA_12_MESES_GERADA_SISTEMA'
  | 'TERMO_DE_ADESAO_GERADO_SISTEMA'
  | 'CERTIDAO_NEGATIVA_DEBITOS_ESTADUAL'
  | 'CERTIDAO_NEGATIVA_DEBITOS_MUNICIPAIS_FORTALEZA'
  | 'CERTIDAO_NEGATIVA_DEBITOS_MUNICIPAIS_MUNICIPIO_EMPREENDIMENTO'
  | 'PARECER_AVALIACAO_BENEFICIARIO'
  | 'DOCUMENTO_ABERTURA_EMPENHO'

export async function uploadFileBeneficiary(
  fileType: TypeFileBeneficiary,
  fileDescription: TypeFileBeneficiaryTypes,
  fileList: FileList | undefined,
  benCpf?: string
): Promise<void> {
  const user = getInfoUser()
  if (!fileList || fileList.length === 0 || !user) {
    return
  }

  const dataFormatedPost = {
    aneDscTipAnexo: fileType,
    anbDscTipDocAnexoBeneficiario: fileDescription,
    benCpf: benCpf != undefined ? benCpf : user.cpfCnpj
  }

  const formData = new FormData()

  formData.append(
    'anexo',
    new Blob([JSON.stringify(dataFormatedPost)], {
      type: 'application/json'
    })
  )

  const fileArray = Array.from(fileList)
  for (const file of fileArray) {
    formData.append('file', file)
  }

  await apiMoradia.post(`beneficiarios/anexos`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return
}

export async function isCadastroAtivo(): Promise<boolean> {
  try {
    const response = await apiMoradia.get<boolean>(
      `parametrizacao/status-cadastro-beneficiario`
    )
    return response.data
  } catch (error) {
    console.error('Não foi possível verificar cadastro do usuário.', error)
    throw error
  }
}

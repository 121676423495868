function fileListFrom(files: File[]): FileList {
  const b = new ClipboardEvent('').clipboardData || new DataTransfer()
  for (const file of files) b.items.add(file)
  return b.files
}

function dataURLtoFile(dataurl: string, filename: string): File {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)![1]
  const bstr = atob(arr[arr.length - 1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

const concatFileList = (
  newValueImg: FileList | undefined,
  oldValue: FileList | undefined
) => {
  if (newValueImg === undefined && oldValue === undefined)
    return fileListFrom([])

  const fileArrayNew = Array.from(newValueImg || [])
  const fileArrayOld = Array.from(oldValue || [])

  const newImagesFilePrev: File[] = fileArrayOld.concat(fileArrayNew)
  const limitFiles = newImagesFilePrev.slice(0, 10)

  const newFileList = fileListFrom(limitFiles)

  return newFileList
}

const transformAndDeleteAtIndex = (
  fileList: FileList | undefined,
  indexToDelete: number
) => {
  if (
    !fileList ||
    fileList.length === 0 ||
    indexToDelete < 0 ||
    indexToDelete >= fileList.length ||
    (fileList?.length === 1 && indexToDelete === 0)
  ) {
    return fileListFrom([])
  }

  const fileArray = Array.from(fileList)

  fileArray.splice(indexToDelete, 1)

  const newFileList = fileListFrom(fileArray)

  return newFileList
}

function generateStrongPassword(length: number) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+'
  let password = ''
  for (let i = 0; i < length; i++) {
    const index = Math.floor(Math.random() * characters.length)
    password += characters.charAt(index)
  }
  return password
}

function isEmptyObject(object: object) {
  if (object === null || object === undefined) return true
  return Object.keys(object).length === 0
}
export * from './pdf'
export {
  transformAndDeleteAtIndex,
  concatFileList,
  dataURLtoFile,
  fileListFrom,
  generateStrongPassword,
  isEmptyObject
}

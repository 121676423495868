'use client'

import { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { GlobalStyles } from '@/styles/global'

import { useAppAccessibility } from '@/context/accessibilityContext'
import { AuthContextProvider } from '@/context/authContext'

import theme from '@/styles/theme'
import VLibras from 'vlibras-nextjs'
import * as S from './styles'

import 'cegov-antd/dist/index.css'
import { FloatButton } from 'antd'
import CookieBanner from '@/components/CookieBanner'

const queryClient = new QueryClient()

export function RootLayoutCustom({ children }: { children: React.ReactNode }) {
  const { lengthText } = useAppAccessibility()

  return (
    <>
      <AuthContextProvider>
        <S.VLibrasContainer>
          <VLibras forceOnload />
        </S.VLibrasContainer>
        <FloatButton.BackTop
          style={{
            top: '90%',
            zIndex: 10000
          }}
        />
        <ThemeProvider theme={theme}>
          <GlobalStyles lengthText={lengthText} />
          <QueryClientProvider client={queryClient}>
            {children}
          </QueryClientProvider>

          <CookieBanner />
        </ThemeProvider>
      </AuthContextProvider>
    </>
  )
}

export enum ProfileUser {
  ADMIN = 1,
  CAIXA = 2,
  CIDADAO = 3,
  EMPRESA = 4,
  SCIDADES = 5
}

export const profilesServiceRoutes = [
  'admin',
  'caixa',
  'cidadao',
  'empresa',
  'scidades'
]

export type TypeRolesPermisson =
  | 'ROLE_CAIXA_ADMIN'
  | 'ROLE_ADMIN'
  | 'ROLE_CAIXA'
  | 'ROLE_CIDADAO'
  | 'ROLE_EMPRESA'
  | 'ROLE_SCIDADES'

export const profilesPermisson = {
  ROLE_CAIXA_ADMIN: 1,
  ROLE_ADMIN: 0,
  ROLE_CAIXA: 1,
  ROLE_CIDADAO: 2,
  ROLE_EMPRESA: 3,
  ROLE_SCIDADES: 4,
  ROLE_CAIXA_SOLICITANTES: 1,
  ROLE_CAIXA_EMPRESAS: 1,
  ROLE_CAIXA_GERENTE: 1
}

export type TypeStatusEnterprises =
  | 'EM_ANALISE_PELA_SCIDADES'
  | 'APROVADO'
  | 'EM_ANALISE_PELA_CAIXA'
  | 'NAO_APROVADO'
  | 'AGUARDANDO_CORRECAO_INFORMACOES'
  | 'DESATIVADO'
  | 'AGUARDANDO_TERMO'
  | 'ESGOTADO'

export type TypeStatusBeneficiary =
  | 'EM_ANALISE_INICIAL'
  | 'EM_ANALISE_PELA_CAIXA'
  | 'EM_ANALISE_PELA_SCIDADES'
  | 'AGUARDANDO_TERMO'
  | 'AGUARDANDO_CORRECAO_INFORMACOES'
  | 'NEGADO'
  | 'NEGADO_DEFINITIVO'
  | 'SUBSIDIO_LIBERADO'
  | 'SUBSIDIO_NEGADO'
  | 'SOLICITACAO_CANCELADA'

export const statusBeneficiary = {
  EM_ANALISE_INICIAL: 'EM_ANALISE_INICIAL',
  AGUARDANDO_TERMO: 'AGUARDANDO_TERMO',
  EM_ANALISE_PELA_SCIDADES: 'EM_ANALISE_PELA_SCIDADES',
  EM_ANALISE_PELA_CAIXA: 'EM_ANALISE_PELA_CAIXA',
  AGUARDANDO_CORRECAO_INFORMACOES: 'AGUARDANDO_CORRECAO_INFORMACOES',
  NEGADO: 'NEGADO',
  NEGADO_DEFINITIVO: 'NEGADO_DEFINITIVO',
  SOLICITACAO_CANCELADA: 'SOLICITACAO_CANCELADA',
  SUBSIDIO_LIBERADO: 'SUBSIDIO_LIBERADO',
  SUBSIDIO_NEGADO: 'SUBSIDIO_NEGADO'
}

export const statusEnterprises = {
  EM_ANALISE_PELA_SCIDADES: 'EM_ANALISE_PELA_SCIDADES',
  APROVADO: 'APROVADO',
  EM_ANALISE_PELA_CAIXA: 'EM_ANALISE_PELA_CAIXA',
  NAO_APROVADO: 'NAO_APROVADO',
  AGUARDANDO_CORRECAO_INFORMACOES: 'AGUARDANDO_CORRECAO_INFORMACOES',
  DESATIVADO: 'DESATIVADO',
  AGUARDANDO_TERMO: 'AGUARDANDO_TERMO',
  ESGOTADO: 'ESGOTADO'
}

export const KEY_STORAGE_USER = 'userInfoMoraria'
export const KEY_STORAGE_COMPANY = 'companyInfoMoraria'
export const KEY_STORAGE_FIRST_NAME = 'firstNameInfoMoraria'

export const KEY_CACHE_TOKEN = 'authTokenMoradia'
export const KEY_CACHE_PROFILE_USER = 'profileUserMoradia'

'use client'
import React, { useEffect, useState } from 'react'

import { ButtonUI } from '../UI/ButtonUI'
import * as S from './styles'

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent')
    if (!cookieConsent) {
      setIsVisible(true)
    }
  }, [])

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true')
    setIsVisible(false)
  }

  if (!isVisible) {
    return null
  }

  return (
    <S.CookieBannerContainer>
      <div className="container">
        <S.CookieBannerWrapper>
          <S.IconWrapper>
            <S.StyledCookieIcon />
          </S.IconWrapper>
          <S.TextWrapper>
            Nós usamos <i style={{ fontStyle: 'italic' }}>cookies</i> para
            personalizar sua experiência no nosso site. Ao continuar navegando
            no nosso site, você concorda com uso de
            <i style={{ fontStyle: 'italic' }}> cookies</i>.
          </S.TextWrapper>
          <ButtonUI buttonType="primary" size="medium" onClick={handleAccept}>
            Entendi
          </ButtonUI>
        </S.CookieBannerWrapper>
      </div>
    </S.CookieBannerContainer>
  )
}

export default CookieBanner

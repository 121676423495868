import styled from 'styled-components'

export const VLibrasContainer = styled.div`
  left: initial !important;
  right: 14px !important;
  top: 70% !important;
  bottom: initial !important;
  transform: translateY(calc(-50% - 10px)) !important;

  position: fixed !important;
  z-index: 10000 !important;
`

export default {
  border: {
    radius: '0.4rem'
  },
  font: {
    primary:
      "var(--font-kanit), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    secondary:
      "var(--font-raleway), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    light: 300,
    normal: 400,
    semiBold: 500,
    bold: 600,
    extraBold: 700,
    sizes: {
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '1.8rem',
      xlarge: '2.4rem',
      xxlarge: '4.8rem',
      xxxlarge: '5.8rem'
    }
  },
  colors: {
    green100: '#BBFF9B',
    green400: '#4caa7e',
    green500: '#1C8E38',
    green700: '#1A6F32',
    greenGray100: '#DAD7CD',
    greenGray300: '#A3B18A',
    greenGray400: '#588157',
    brandGreen500: '#1c8e38',
    brandGreen600: '#188036',
    brandGreen700: '#1a6f32',
    greenLight: '#F2FFF9',
    brandOrange: '#e4591e',
    brandOrange500: '#E4591E',
    brandYellow400: '#FBC543',
    brandYellow500: '#f59e1b',
    primary500: '#80b918',
    primaryBright: '#aacc00',
    secondary: '#4caa7e',
    secondaryBringt: '#32c781',
    danger: '#e30447',
    dangerBg: '#FFECEC',
    dangerBright: '#ff2164',
    dangerLight: '#ff9c9c',
    dangerDark: '#7c0025',
    warning: '#ffe169',
    warningLight: '#FFF5CC',
    warningDark: '#5f542e',
    info: '#1b98e0',
    infoLight: '#a1d5f3',
    infoDark: '#0e5882',
    info100: '#E5F2FA',
    white: '#fbfbfb',
    gray100: '#f8f9fa',
    gray200: '#e9ecef',
    gray300: '#dee2e6',
    gray400: '#ced4da',
    gray500: '#adb5bd',
    gray600: '#6c757d',
    gray700: '#495057',
    gray800: '#343A40',
    black: '#081c15',
    focus: '#7678ed',
    bg: '#ebefec',
    blue300: '#21bcff',
    blue400: '#049ccc',
    yellowLight: '#fff5cc'
  },
  spacings: {
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem'
  },
  breakpoints: {
    xsm: '@media screen and (max-width: 340px)',
    sm: '@media screen and (max-width: 600px)',
    md: '@media screen and (max-width: 768px)',
    lg: '@media screen and (max-width: 992px)',
    xl: '@media screen and (max-width: 1200px)'
  },
  zIndex: {
    modal: 1010,
    overlay: 900,
    dropdown: 800
  }
} as const

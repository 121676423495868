import styled, { css } from 'styled-components'

interface StyledButtonProps {
  $size: 'small' | 'medium' | 'large' | 'none'
  $iconOnly: boolean
  $buttonType: 'primary' | 'secondary' | 'tertiary' | 'danger'
  $active: boolean
  $loading: boolean
  $disable: boolean
}

const sizeVariants = {
  none: css``,
  small: css`
    padding: 8px 16px;
    font-size: 1.4rem;
  `,
  medium: css`
    padding: 12px 24px;
    font-size: 1.4rem;
  `,
  large: css`
    padding: 16px 32px;
    font-size: 1.6rem;
  `
}

const buttonTypeVariants = {
  primary: css`
    background-color: ${({ theme }) => theme.colors.primary500};
    color: ${({ theme }) => theme.colors.white};
    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryBright};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.primary500};
      border: 2px solid transparent;
    }
  `,
  secondary: css`
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    &:hover {
      background-color: ${({ theme }) => theme.colors.secondaryBringt};
      .icon-btn.only-icon {
        color: ${({ theme }) => theme.colors.white};
      }
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.secondary};
      border: 2px solid transparent;
    }
  `,
  tertiary: css`
    background-color: ${({ theme }) => theme.colors.gray100};
    color: ${({ theme }) => theme.colors.black};
    &:hover {
      background-color: ${({ theme }) => theme.colors.gray300};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.gray100};
      border: 2px solid transparent;
    }
  `,
  danger: css`
    background-color: ${({ theme }) => theme.colors.danger};
    color: ${({ theme }) => theme.colors.white};
    &:hover {
      background-color: ${({ theme }) => theme.colors.dangerBright};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.danger};
      border: 2px solid transparent;
    }
  `
}

export const StyledButton = styled.button<StyledButtonProps>`
  position: relative;
  cursor: pointer;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid #081c15;
  border-radius: 4px;

  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: ${({ theme }) => theme.font.bold};
  font-size: ${({ theme }) => theme.font.sizes.xlarge};

  ${({ $size }) => sizeVariants[$size]};
  ${({ $buttonType }) => buttonTypeVariants[$buttonType]};

  ${({ $iconOnly, theme }) =>
    $iconOnly &&
    css`
      width: 45px;
      height: 45px;
      .icon-btn {
        font-size: ${theme.font.sizes.xlarge};
      }
    `}

  border-radius: 8px;

  &.rounded {
    border-radius: 40px;
  }

  ${({ theme, $active, $buttonType }) =>
    $active &&
    css`
      pointer-events: none;
      background-color: ${theme.colors.focus};
      color: ${$buttonType === 'tertiary'
        ? theme.colors.info
        : theme.colors.black};
    `}

  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
      opacity: 0.9;
    `}

  ${({ $disable }) =>
    $disable &&
    css`
      pointer-events: none;
      background-color: ${({ theme }) => theme.colors.gray600};
      color: ${({ theme }) => theme.colors.black};
    `};

  transition: all 200ms ease-in-out;
`

export const Icon = styled.span<{
  $iconOnly: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 200ms ease-in;
  font-size: 13px;
  ${({ $iconOnly }) =>
    !$iconOnly &&
    css`
      margin-right: 8px;
    `}
`

import Cookies from 'js-cookie'
import { retrieveFromStorage, removeFromStorage } from '../StorageService'

import { apiMoradia } from '@/services/api'

import {
  KEY_STORAGE_FIRST_NAME,
  KEY_CACHE_PROFILE_USER,
  KEY_STORAGE_COMPANY,
  KEY_STORAGE_USER,
  KEY_CACHE_TOKEN
} from '@/constants/user-profiles-enums'
import { TypeApiUserAcesso } from '@/@types/autenticacao'

const logoutUser = () => {
  removeFromStorage(KEY_STORAGE_USER)
  removeFromStorage(KEY_STORAGE_COMPANY)
  removeFromStorage(KEY_STORAGE_FIRST_NAME)

  Cookies.remove(KEY_CACHE_PROFILE_USER)
  Cookies.remove(KEY_CACHE_TOKEN)
}

export type TypeUserAcesso = {
  userId: string
  name: string
}

const getInfoUser = (): TypeApiUserAcesso | null => {
  const responseStorage = retrieveFromStorage(KEY_STORAGE_USER)
  if (responseStorage === null) return null

  return JSON.parse(responseStorage)
}

const isUserLoggedIn = () => {
  const tokenCache = Cookies.get(KEY_CACHE_TOKEN)
  return tokenCache !== undefined && tokenCache !== null && tokenCache !== ''
}

async function findUserAcesso(cpf: string): Promise<TypeUserAcesso> {
  try {
    const response = await apiMoradia.get(`/acesso/buscar-usuario/${cpf}`)

    return response.data
  } catch (error) {
    console.error('Erro ao buscar usuário no Acesso Cidadão:', error)
    throw error
  }
}

export { logoutUser, isUserLoggedIn, getInfoUser, findUserAcesso }

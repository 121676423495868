const addToStorage = (chave: string, valor: string) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(chave, valor)
  }
}

const removeFromStorage = (chave: string) => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(chave)
  }
}

const retrieveFromStorage = (chave: string) => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(chave)
  }
  return null
}

export { addToStorage, removeFromStorage, retrieveFromStorage }

import { TCreatedPdf } from 'pdfmake/build/pdfmake'
import QRCode from 'qrcode'

export function getPdfBase64(pdfObject: TCreatedPdf) {
  return new Promise((resolve, reject) => {
    pdfObject.getBase64((data) => {
      if (data) {
        resolve(data)
      } else {
        reject(new Error('Error fetching PDF Base64'))
      }
    })
  })
}

export const dataUriToBlob = (dataUri: string): Blob => {
  const byteString = atob(dataUri.split(',')[1])
  const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]

  const byteNumbers = new Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)

  return new Blob([byteArray], { type: mimeString })
}

export const downloadBlob = (blob: Blob, filename: string): void => {
  const url = URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.href = url
  a.download = filename

  document.body.appendChild(a)

  a.click()

  document.body.removeChild(a)

  URL.revokeObjectURL(url)
}

export const generateQR = async (text: string, dark?: string, light?: string) => {
  const opts: QRCode.QRCodeToDataURLOptions = {
    errorCorrectionLevel: 'H',
    type: 'image/png',
    margin: 1,
    color: {
      dark: dark ? dark : '#000000',
      light: light ? light : '#F4F4F4'
    }
  }

  try {
    return await QRCode.toDataURL(text, opts)
  } catch (err) {
    console.error(err)
  }
}
